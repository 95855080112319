import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAuthStore } from 'redux/reducers/auth/types';

// ==========================================:
const getAuthState = (state: IStoreState): IAuthStore => state.auth;
export const getAuth = createSelector([getAuthState], (auth: IAuthStore) => auth);

// ====================================================:
export const getAuthIsAuthenticated = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => !!(auth.isAuthenticated && auth.accessToken),
);

// ====================================================:
export const getAuthIsNotAuthenticated = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => !auth.isAuthenticated && !auth.accessToken,
);
export const getUserData = createSelector([getAuth], (auth: IAuthStore) => auth.userData);

export const getUserFeesData = createSelector([getAuth], (auth: IAuthStore) => auth.fees);

export const getUserTwoFaStatus = createSelector(
	[getAuth],
	(auth: IAuthStore) => auth.userData?.google2fa_enabled,
);
