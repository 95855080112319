import TableHeader from './TableHeader';
import TableBody from './TableBody';

const FiatFiatTable = () => {
	return (
		<div className="table-block table-block--mt-0 mb-0">
			<div className="table-wrapper">
				<div className="table table--fiat-fiat">
					<div className="table__title">Fiat-fiat trades</div>
					<TableHeader />
					<TableBody />
				</div>
			</div>
		</div>
	);
};
export default FiatFiatTable;
