import React from 'react';
import { Helmet } from 'react-helmet';
import logo from 'assets/dist/img/logo-footer.svg';
import maintenanceIcon from 'assets/dist/img/maintenance-icon.svg';
import Dashboard from '../../layouts/Dashboard';

const MaintenancePage = () => {
	return (
		<>
			<Helmet>
				<body className="maintenance-page" />
			</Helmet>
			<Dashboard title="Maintenance">
				<section className="maintenance">
					<div className="maintenance__logo">
						<a href="!#">
							<img alt="Logo" src={logo} />
						</a>
					</div>
					<div className="maintenance__content">
						<div className="maintenance__icon">
							<img src={maintenanceIcon} alt="maintenance icon" />
						</div>
						<div className="maintenance__title">Platform maintenance in progress</div>
						<div className="maintenance__text">
							We are temporarily offline for essential updates. You will be able to resume
							operations shortly.
						</div>
					</div>
				</section>
			</Dashboard>
		</>
	);
};
export default MaintenancePage;
