import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Auth from 'layouts/Auth';
import { getUserData } from 'redux/reducers/auth/selectors';
import { getUserRequest } from 'redux/reducers/auth/reducer';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import parsePhoneNumber, { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import { getNameList } from 'country-list';
import { statusClassNames, statusNames } from './utiils';
import WalletSideBar from '../Wallets/WalletSideBar/WalletSideBar';
import PhoneInputField from '../../ui/PhoneInputField';
import Input from '../../ui/Formik/Input';
import { ICountry } from '../PaymentMethods/BankAccountForm/BankAccountForm';
import CountriesSelect from '../../ui/Formik/Select/CountriesSelect';
import { capitalizeWords } from '../../services/utils/strings';
import { getUserFeeRequest } from '../../redux/reducers/users/reducer';
import FeesTable from './FeesTable';
import BankingTable from './BankingTable';
import FiatFiatTable from './FiatFiatTable';

const CustomInput = (props: any) => (
	<input className="form-control" type="text" {...props} readOnly />
);

const AccountDetails = () => {
	const dispatch = useDispatch();
	const user = useSelector(getUserData);
	const [userPhone, setUserPhone] = useState('');
	const [initialUserPhone, setInitialUserPhone] = useState('');
	const [phonePrefix, setPhonePrefix] = useState<string>('1');
	const [initialPhonePrefix, setInitialPhonePrefix] = useState<string>('1');
	const [countries, setCountries] = useState<[] | ICountry[]>([]);
	const [selectedCompanyCountry, setSelectedCompanyCountry] = useState<ICountry>({
		value: '',
		label: '',
	});

	const [counterStateCode, setStateCounterCode] = useState('US');
	const onChange = (value: string, counterCode: string) => {
		setPhonePrefix(value);
		setStateCounterCode(counterCode);
	};
	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const [countryFlag, setCountryFlag] = useState<any>(null);
	const [countryName, setCountryName] = useState<any>(null);

	useEffect(() => {
		/* fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code')
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
			})
			.catch((error) => console.log(error)); */
		const result = Object.entries(getNameList()).map(([key, value]) => ({
			label: capitalizeWords(key),
			value,
		}));
		setCountries(result);
	}, []);

	useEffect(() => {
		if (user?.type === 'corporate' && user?.data?.company_country_registration) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			let userCountry = user?.data?.company_country_registration.replace(
				/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
				'',
			);
			if (userCountry.startsWith(' ')) {
				userCountry = userCountry.substring(1);
			}
			const fileteredCountry = countries.filter((item) => item.label.includes(userCountry));
			if (fileteredCountry) {
				setCountryFlag(fileteredCountry[0]?.value);
				setCountryName(userCountry);
			}
		}
	}, [countries, user]);

	useEffect(() => {
		setSelectedCompanyCountry({
			value: countryFlag,
			label: countryName,
		});
	}, [countryFlag, countryName]);

	const onChangePhone = (value: string) => {
		setPhonePrefix(value);
		setUserPhone('');
	};

	let validationShape;
	if (user?.type === 'corporate') {
		validationShape = yup.object().shape({
			first_name: yup.string().required('Please enter your first name.'),
			last_name: yup.string().required('Please enter your last name.'),
			email: yup
				.string()
				.email('Please enter a valid email address.')
				.required('Please enter your email.'),
			phone: yup.string().required('Please enter your phone.'),
			position: yup.string().required('Please enter your position.'),
			company_name: yup.string().required('Please enter your company name.'),
			company_number: yup.string().required('Please enter your company registration number.'),
			country: yup.string().required('Please choose your country.'),
		});
	} else {
		validationShape = yup.object().shape({
			first_name: yup.string().required('Please enter your first name.'),
			last_name: yup.string().required('Please enter your last name.'),
			email: yup
				.string()
				.email('Please enter a valid email address.')
				.required('Please enter your email.'),
			phone: yup.string().required('Please enter your phone.'),
		});
	}
	const validationSchema = validationShape;

	let initialValues;
	if (user?.type === 'corporate') {
		initialValues = {
			first_name: user?.data?.first_name,
			last_name: user?.data?.last_name,
			email: user?.email,
			phone: userPhone,
			position: user?.data?.position,
			company_name: user?.data?.company_name,
			company_number: user?.data?.company_registration_number,
			country: user?.data?.company_country_registration,
		};
	} else {
		initialValues = {
			first_name: user?.data?.first_name,
			last_name: user?.data?.last_name,
			email: user?.email,
			phone: userPhone,
		};
	}

	useEffect(() => {
		dispatch(getUserRequest());
	}, [dispatch]);

	useEffect(() => {
		if (user?.data?.phone) {
			const fullPhone = String(user?.data?.phone).replace('+', '');
			const phoneNumber = parsePhoneNumber(`+${fullPhone}`);
			const country = phoneNumber?.country || 'US';
			// console.log(country);
			const countryCallingCode = getCountryCallingCode(country);
			// console.log(countryCallingCode);
			setPhonePrefix(countryCallingCode);
			setStateCounterCode(country);
			setInitialPhonePrefix(countryCallingCode);
			setUserPhone(fullPhone.replace(countryCallingCode, ''));
			setInitialUserPhone(fullPhone.replace(countryCallingCode, ''));
			// console.log(fullPhone.replace(countryCallingCode, ''));
		}

		if (user && user.data.user_id) {
			const params: any = {
				id: user.data.user_id,
				current_page: 1,
				per_page: 1000,
			};
			// dispatch(getUserFeeRequest(params));
		}
	}, [dispatch, user]);

	return (
		<>
			<Auth>
				<section className="wallet-section">
					<div className="page-wrap">
						<WalletSideBar />
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { duration: 0.4 } }}
							exit={{ opacity: 0, transition: { duration: 0.4 } }}
						>
							<div className="wallet-content">
								<Formik
									enableReinitialize
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={(values, { resetForm, setSubmitting }) => {
										console.log(
											JSON.stringify(
												{
													...values,
													phone: phonePrefix + values.phone,
													country:
														selectedCompanyCountry.label !== ''
															? selectedCompanyCountry.label
															: user?.data?.company_country_registration,
												},
												null,
												2,
											),
										);
									}}
								>
									{({ resetForm, errors, touched, setFieldTouched }) => {
										return (
											<Form>
												<div className="wallet-content-header wallet-content-header--mb-40">
													<div className="wallet-content-header__title">
														<p>Account Settings</p>
													</div>
													<div className="wallet-content-header__buttons flex flex-e flex-m">
														{/* <button type="submit" className="btn btn-primary">
													Save
												</button>
												<button
													type="button"
													className="btn btn-danger"
													onClick={() => {
														resetForm();
														setPhonePrefix(initialPhonePrefix);
														setUserPhone(initialUserPhone);
														setSelectedCompanyCountry({
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-ignore
															value: user?.data?.company_country_registration,
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-ignore
															label: user?.data?.company_country_registration,
														});
													}}
												>
													Cancel
												</button> */}
													</div>
												</div>

												<div className="setting-content">
													<div className="account-form">
														<div className="form-row">
															<div className="form-group">
																<label htmlFor="first_name">First Name</label>
																<Field
																	type="text"
																	name="first_name"
																	id="first_name"
																	as={CustomInput}
																/>
																{errors.first_name && touched.first_name ? (
																	<div className="input__notification input__notification--type2 input__notification--ta-r">
																		<p>{errors.first_name}</p>
																	</div>
																) : null}
															</div>
															<div className="form-group">
																<label htmlFor="last_name">Last Name</label>
																<Field
																	type="text"
																	name="last_name"
																	id="last_name"
																	as={CustomInput}
																/>
																{errors.last_name && touched.last_name ? (
																	<div className="input__notification input__notification--type2 input__notification--ta-r">
																		<p>{errors.last_name}</p>
																	</div>
																) : null}
															</div>
														</div>

														<div className="form-row">
															<div className="form-group">
																<label htmlFor="email">Email Address</label>
																<Field type="email" name="email" id="email" as={CustomInput} />
																{errors.email && touched.email ? (
																	<div className="input__notification input__notification--type2 input__notification--ta-r">
																		<p>{errors.email}</p>
																	</div>
																) : null}
															</div>
															<div className="form-group">
																<label htmlFor="phone">Phone Number</label>
																<PhoneInputField
																	counterStateCode={counterStateCode}
																	onChange={onChangePhone}
																	value={phonePrefix}
																	enableSearch
																	dropdownClass="phone-dropdown"
																	searchClass="searchfield"
																	isDisabled
																>
																	<Field
																		type="tel"
																		placeholder="Phone Number"
																		name="phone"
																		id="phone"
																		component={Input}
																		readonly
																	/>
																</PhoneInputField>
															</div>
														</div>

														{user?.type === 'corporate' ? (
															<>
																<div className="form-row">
																	<div className="form-group">
																		<label htmlFor="position">Position</label>
																		<Field
																			type="text"
																			name="position"
																			id="position"
																			as={CustomInput}
																		/>
																		{errors.position && touched.position ? (
																			<div className="input__notification input__notification--type2 input__notification--ta-r">
																				<p>{errors.position}</p>
																			</div>
																		) : null}
																	</div>
																	<div className="form-group">
																		<label htmlFor="company_name">Company Name</label>
																		<Field
																			type="text"
																			name="company_name"
																			id="company_name"
																			as={CustomInput}
																		/>
																		{errors.company_name && touched.company_name ? (
																			<div className="input__notification input__notification--type2 input__notification--ta-r">
																				<p>{errors.company_name}</p>
																			</div>
																		) : null}
																	</div>
																</div>

																<div className="form-row">
																	<div className="form-group">
																		<label htmlFor="company_number">
																			Company Registration Number
																		</label>
																		<Field
																			type="text"
																			name="company_number"
																			id="company_number"
																			as={CustomInput}
																		/>
																		{errors.company_number && touched.company_number ? (
																			<div className="input__notification input__notification--type2 input__notification--ta-r">
																				<p>{errors.company_number}</p>
																			</div>
																		) : null}
																	</div>
																	{!!countryFlag && (
																		<div className="form-group">
																			<Field
																				title="Country of Registration"
																				type="text"
																				placeholder="Select Country"
																				searchField
																				name="country"
																				component={CountriesSelect}
																				arr={countries}
																				onChange={setSelectedCompanyCountry}
																				setTouched={() => setFieldTouched('country')}
																				touched={touched.country}
																				resetCustomSelect={resetCustomSelect}
																				setResetCustomSelect={setResetCustomSelect}
																				activeValue={selectedCompanyCountry || undefined}
																				isDisabled
																			/>
																			{errors.country && touched.country ? (
																				<div className="input__notification input__notification--type2 input__notification--ta-r">
																					<p>{errors.country}</p>
																				</div>
																			) : null}
																		</div>
																	)}
																</div>
															</>
														) : null}

														<div className="form-row">
															<div className="account-form__info">
																<div className="account-form__info-text">Verification</div>
																{user && (
																	<div
																		className={`account-form__verification-status ${
																			statusClassNames[user?.status_id]
																		}`}
																	>
																		{statusNames[user?.status_id]}
																	</div>
																)}
															</div>
														</div>
													</div>
												</div>
											</Form>
										);
									}}
								</Formik>
								<div className="account-settings-section">
									<div className="account-settings-section__title">Fees</div>
									<FeesTable />
								</div>
								<div className="account-settings-section">
									<FiatFiatTable />
								</div>
								<div className="account-settings-section">
									<BankingTable />
								</div>
							</div>
						</motion.div>
					</div>
				</section>
			</Auth>
		</>
	);
};

export default AccountDetails;
