import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: any = {
	userFees: null,
	oneUserFeesLoading: false,
};

const users = createSlice({
	name: '@@users',
	initialState,
	reducers: {
		getUserFeeRequest: (state, action: PayloadAction<any>) => {
			const newState = state;
			newState.oneUserFeesLoading = true;
		},
		getUserFeeSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.userFees = payload;
			newState.oneUserFeesLoading = false;
		},
	},
});

export default users.reducer;
export const { getUserFeeRequest, getUserFeeSuccess } = users.actions;
