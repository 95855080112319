import React from 'react';
import logo from 'assets/dist/img/logo-header-v2.svg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ROUTES } from 'routes/constants';

const Header = () => {
	const history = useHistory();
	return (
		<>
			<Link to="/" className="header__logo">
				<img alt="Logo" src={logo} />
			</Link>
			{history.location.pathname !== '/login' &&
				history.location.pathname !== '/reset-password' &&
				history.location.pathname !== '/confirmation' &&
				history.location.pathname !== '/registration/twoFa/' &&
				history.location.pathname !== ROUTES.CREATE_PASSWORD && (
					<div className="header__nav">
						<Link to="/login" type="button" className="btn btn-primary">
							Log In
						</Link>
					</div>
				)}
		</>
	);
};

export default Header;
