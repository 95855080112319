import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { navList } from 'routes/routesList';
import IconSvg from 'ui/Svg/IconSvg';
import Logo from 'assets/img/sidebar_logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// import { getTwoFaStatusRequest, logoutRequest } from '../../../redux/reducers/auth/reducer';
import { getMessagesRequest, getUnreadMessageRequest } from 'redux/reducers/messages/reducer';
import { getCounter } from 'redux/reducers/messages/selectors';
import { logoutRequest } from '../../../redux/reducers/auth/reducer';
import { statusNames } from '../../Verification/drop-file-input/utiils';
import { getUserData } from '../../../redux/reducers/auth/selectors';

const WalletSideBar = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const counter = useSelector(getCounter);
	// const [status, setTwoFaStatus] = useState(false);
	// const google2faEnabled = useSelector((state: any) => state.userData.google2fa_enabled);

	const getNavLinkClass = (path: string[]) => {
		if (path.indexOf(location.pathname) !== -1) {
			return 'sidebar-nav-item__link active';
		}
		return 'sidebar-nav-item__link';
	};

	const submenuParentClickHandler = (event: any) => {
		const target = event.target.closest('.sidebar-nav-item__link');
		if (target.classList.contains('active')) {
			target.classList.remove('active');
		} else {
			target.classList.add('active');
		}
	};

	// useEffect(() => {
	// 	dispatch(getTwoFaStatusRequest({ setTwoFaStatus }));
	// }, [dispatch]);

	// useEffect(() => {
	// 	console.log(userData?.status?.name);
	// }, [userData]);
	const data = [
		{ id: 1, name: 'Cody Fisher', active: true },
		{ id: 2, name: 'Praise Network LTD', active: false },
		{ id: 3, name: 'Northbank Corp', active: false },
	];
	const [activeId, setActiveId] = useState<number>(
		data.find((item) => item.active)?.id || data[0].id,
	);
	const [showAll, setShowAll] = useState<boolean>(false);

	// Функция для изменения активного элемента
	const handleActiveChange = (id: number): void => {
		setActiveId(id);
		setShowAll(false); // Скрываем список после выбора
	};

	// Получаем активный элемент
	const activeItem = data.find((item) => item.id === activeId);
	const determineColor = (id: number) => {
		switch (id % 3) {
			case 0:
				return 'blue';
			case 1:
				return 'orange';
			case 2:
				return 'purple';
			default:
				return 'grey'; // default color if needed
		}
	};

	useEffect(() => {
		if (userData?.google2fa_enabled) {
			dispatch(getUnreadMessageRequest({ current_page: 1, per_page: 999999 }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);
	return (
		<div className="sidebar-nav">
			<div className="sidebar-nav__logo">
				<img src={Logo} alt="" />
			</div>
			<div className="sidebar-nav-item">
				<nav className="sidebar-nav-item__list">
					{!!userData && (
						<>
							{statusNames[userData?.status?.name] === 'Unverified' ||
							statusNames[userData?.status?.name] === 'Pending' ||
							statusNames[userData?.status?.name] === 'Rejected' ? (
								<ul>
									<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
										<div
											className={getNavLinkClass([
												'/verification',
												'/your-accounts',
												'/security-settings',
												'/referrals',
												'/security-settings/2fa',
												'/account-settings',
											])}
											onClick={submenuParentClickHandler}
										>
											<div className="sidebar-nav-item__icon">
												<IconSvg name="account-settings" w="20" h="20" />
											</div>
											<div className="sidebar-nav-item__text">
												<p>Account Settings</p>
											</div>
										</div>
										<div className="sidebar-nav-item__submenu">
											<ul>
												<li>
													<NavLink
														to="/account-settings"
														className={getNavLinkClass(['/account-settings'])}
														activeClassName=""
													>
														<div className="sidebar-nav-item__icon">
															<IconSvg name="security-settings" w="24" h="24" />
														</div>
														<div className="sidebar-nav-item__text">
															<p>Settings</p>
														</div>
													</NavLink>
												</li>
												{!!userData && (
													<>
														{statusNames[userData?.status_id] !== 'Verified' && (
															<li>
																<NavLink
																	to="/verification"
																	className={getNavLinkClass(['/verification'])}
																	activeClassName=""
																>
																	<div className="sidebar-nav-item__icon">
																		<IconSvg name="verification" w="24" h="24" />
																	</div>
																	<div className="sidebar-nav-item__text">
																		<p>Verification</p>
																	</div>
																</NavLink>
															</li>
														)}
													</>
												)}
											</ul>
										</div>
									</li>
									<li>
										<button
											onClick={() => {
												dispatch(logoutRequest({ history }));
											}}
											type="button"
											className="sidebar-nav-item__link"
										>
											<div className="sidebar-nav-item__icon">
												<IconSvg name="logout" w="24" h="24" />
											</div>
											<div className="sidebar-nav-item__text">
												<p>Log Out</p>
											</div>
										</button>
									</li>
								</ul>
							) : (
								<>
									{!userData?.google2fa_enabled ? (
										<ul className="not google2fa_enabled">
											<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
												<div
													className={getNavLinkClass([
														'/verification',
														'/your-accounts',
														'/security-settings',
														'/referrals',
														'/security-settings/2fa',
														'/account-settings',
													])}
													onClick={submenuParentClickHandler}
												>
													<div className="sidebar-nav-item__icon">
														<IconSvg name="account-settings" w="20" h="20" />
													</div>
													<div className="sidebar-nav-item__text">
														<p>Account Settings</p>
													</div>
												</div>
												<div className="sidebar-nav-item__submenu">
													<ul>
														<li>
															<NavLink
																to="/account-settings"
																className={getNavLinkClass(['/account-settings'])}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon">
																	<IconSvg name="security-settings" w="24" h="24" />
																</div>
																<div className="sidebar-nav-item__text">
																	<p>Settings</p>
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to="/security-settings"
																className={getNavLinkClass([
																	'/security-settings',
																	'/security-settings/2fa',
																])}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon">
																	<IconSvg name="security-settings" w="24" h="24" />
																</div>
																<div className="sidebar-nav-item__text">
																	<p>Security</p>
																</div>
															</NavLink>
														</li>
													</ul>
												</div>
											</li>
											<li>
												<button
													onClick={() => {
														dispatch(logoutRequest({ history }));
													}}
													type="button"
													className="sidebar-nav-item__link"
												>
													<div className="sidebar-nav-item__icon">
														<IconSvg name="logout" w="24" h="24" />
													</div>
													<div className="sidebar-nav-item__text">
														<p>Log Out</p>
													</div>
												</button>
											</li>
										</ul>
									) : (
										<ul>
											{/* <div className="workspace"> */}
											{/*	<div */}
											{/*		className={`workspace-item ${showAll ? 'active' : ''}`} */}
											{/*		onClick={() => setShowAll(!showAll)} */}
											{/*		style={{ cursor: 'pointer', marginBottom: '6px' }} */}
											{/*	> */}
											{/*		<div className={`workspace-icon ${determineColor(activeItem?.id || 1)}`}> */}
											{/*			<p>{activeItem?.name.slice(0, Math.min(2, activeItem?.name.length))}</p> */}
											{/*		</div> */}
											{/*		<span>{activeItem?.name}</span> */}
											{/*		<img */}
											{/*			src="data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.75 7.5L9.35355 10.8964C9.15829 11.0917 8.84171 11.0917 8.64645 10.8964L5.25 7.5' stroke='%23E4E4E4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A" */}
											{/*			alt="" */}
											{/*			style={{ */}
											{/*				transform: showAll ? 'rotate(180deg)' : 'rotate(0deg)', */}
											{/*				transition: 'transform 0.3s ease', */}
											{/*			}} */}
											{/*		/> */}
											{/*	</div> */}
											{/*	{showAll && ( */}
											{/*		<div> */}
											{/*			{data */}
											{/*				.filter((item) => item.id !== activeId) */}
											{/*				.map((item) => ( */}
											{/*					<div */}
											{/*						key={item.id} */}
											{/*						onClick={() => handleActiveChange(item.id)} */}
											{/*						style={{ cursor: 'pointer', marginBottom: '6px' }} */}
											{/*					> */}
											{/*						<div className="workspace-item"> */}
											{/*							<div className={`workspace-icon ${determineColor(item.id)}`}> */}
											{/*								<p>{item?.name.slice(0, Math.min(2, item?.name.length))}</p> */}
											{/*							</div> */}
											{/*							<span>{item?.name}</span> */}
											{/*						</div> */}
											{/*					</div> */}
											{/*				))} */}
											{/*		</div> */}
											{/*	)} */}
											{/* </div> */}
											<li>
												<NavLink to={navList.wallets.path} activeClassName="active">
													<div className="sidebar-nav-item__icon">
														<IconSvg name="wallet" w="20" h="20" />
													</div>
													<div className="sidebar-nav-item__text">
														<p>Wallets</p>
													</div>
												</NavLink>
											</li>
											<li>
												<NavLink to={navList.trade.path} activeClassName="active">
													<div className="sidebar-nav-item__icon">
														<IconSvg name="trade" w="20" h="20" />
													</div>
													<div className="sidebar-nav-item__text">
														<p>Trade</p>
													</div>
												</NavLink>
											</li>
											<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
												<div
													className={getNavLinkClass([
														navList.depositFiat.path,
														navList.depositCrypto.path,
														navList.withdrawFiat.path,
														navList.withdrawCripto.path,
													])}
													onClick={submenuParentClickHandler}
												>
													<div className="sidebar-nav-item__icon">
														<IconSvg name="payments" w="20" h="20" />
													</div>
													<div className="sidebar-nav-item__text">
														<p>Payments</p>
													</div>
												</div>
												<div className="sidebar-nav-item__submenu">
													<ul>
														<li>
															<NavLink
																to={navList.depositFiat.path}
																className={getNavLinkClass([
																	navList.depositFiat.path,
																	navList.depositCrypto.path,
																])}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon">
																	<IconSvg name="deposit" w="20" h="20" />
																</div>
																<div className="sidebar-nav-item__text">
																	<p>Deposit</p>
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to={navList.withdrawFiat.path}
																className={getNavLinkClass([
																	navList.withdrawFiat.path,
																	navList.withdrawCripto.path,
																])}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon">
																	<IconSvg name="withdrawal" w="20" h="20" />
																</div>
																<div className="sidebar-nav-item__text">
																	<p>Withdrawal</p>
																</div>
															</NavLink>
														</li>
													</ul>
												</div>
											</li>
											<li>
												<NavLink to={navList.paymentMethods.path} activeClassName="active">
													<div className="sidebar-nav-item__icon">
														<IconSvg name="beneficiaries" w="20" h="20" />
													</div>
													<div className="sidebar-nav-item__text">
														<p>Beneficiaries</p>
													</div>
												</NavLink>
											</li>
											{/* <li> */}
											{/*	<NavLink to={navList.transactionHistory.path} activeClassName="active"> */}
											{/*		<div className="sidebar-nav-item__icon"> */}
											{/*			<IconSvg name="wallet" w="24" h="24" /> */}
											{/*		</div> */}
											{/*		<div className="sidebar-nav-item__text"> */}
											{/*			<p>Transaction History</p> */}
											{/*		</div> */}
											{/*	</NavLink> */}
											{/* </li> */}
											<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
												<div
													className={
														location.state &&
														// eslint-disable-next-line @typescript-eslint/ban-ts-comment
														// @ts-ignore
														(location.state.fromTradeHistory ||
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-ignore
															location.state.fromDepositHistoryCrypto ||
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-ignore
															location.state.fromDepositFiatHistory ||
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-ignore
															location.state.fromWithdrawalHistoryCrypto ||
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-ignore
															location.state.fromStatementReports ||
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-ignore
															location.state.fromWithdrawalFiatHistory)
															? 'sidebar-nav-item__link active'
															: getNavLinkClass([
																	'/history/trade',
																	'/history/deposit-crypto',
																	'/history/deposit-fiat',
																	'/history/withdrawal-crypto',
																	'/history/withdrawal-fiat',
																	'/history/statement',
															  ])
													}
													onClick={submenuParentClickHandler}
												>
													<div className="sidebar-nav-item__icon">
														<IconSvg name="history" w="20" h="20" />
													</div>
													<div className="sidebar-nav-item__text">
														<p>History</p>
													</div>
												</div>
												<div className="sidebar-nav-item__submenu">
													<ul>
														<li>
															<NavLink
																to={navList.transactionsTrade.path}
																className={
																	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																	// @ts-ignore
																	location.state && location.state.fromTradeHistory
																		? 'sidebar-nav-item__link active'
																		: getNavLinkClass(['/history/trade'])
																}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon">
																	<IconSvg name="trade-history" w="24" h="24" />
																</div>
																<div className="sidebar-nav-item__text">
																	<p>Trade History</p>
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to={`${navList.depositsHistory.path}/deposit-crypto`}
																className={
																	location.state &&
																	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																	// @ts-ignore
																	(location.state.fromDepositHistoryCrypto ||
																		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																		// @ts-ignore
																		location.state.fromDepositFiatHistory)
																		? 'sidebar-nav-item__link active'
																		: getNavLinkClass([
																				'/history/deposit-crypto',
																				'/history/deposit-fiat',
																		  ])
																}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon">
																	<IconSvg name="deposit-history" w="24" h="24" />
																</div>
																<div className="sidebar-nav-item__text">
																	<p>Deposit History</p>
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to={`${navList.withdrawalsHistory.path}/withdrawal-crypto`}
																className={
																	location.state &&
																	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																	// @ts-ignore
																	(location.state.fromWithdrawalHistoryCrypto ||
																		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																		// @ts-ignore
																		location.state.fromWithdrawalFiatHistory)
																		? 'sidebar-nav-item__link active'
																		: getNavLinkClass([
																				'/history/withdrawal-crypto',
																				'/history/withdrawal-fiat',
																		  ])
																}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon">
																	<IconSvg name="withdrawal-history" w="24" h="24" />
																</div>
																<div className="sidebar-nav-item__text">
																	<p>Withdrawal History</p>
																</div>
															</NavLink>
														</li>
														{/* <li>
															<NavLink
																to={`${navList.statementHistory.path}`}
																className={
																	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																	// @ts-ignore
																	location.state && location.state.fromStatementReports
																		? 'sidebar-nav-item__link active'
																		: getNavLinkClass(['/history/statement'])
																}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon">
																	<IconSvg name="statement" w="24" h="24" />
																</div>
																<div className="sidebar-nav-item__text">
																	<p>Statement</p>
																</div>
															</NavLink>
														</li> */}
													</ul>
												</div>
											</li>
											<li>
												<NavLink to={navList.information.path} activeClassName="active">
													<div className="sidebar-nav-item__icon">
														<IconSvg name="information" w="20" h="20" />
													</div>
													<div className="sidebar-nav-item__text">
														<p>Information</p>
													</div>
													{!!counter && (
														<span className="sidebar-nav-item__counter">{counter}</span>
													)}
												</NavLink>
											</li>
											<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
												<div
													className={getNavLinkClass([
														'/verification',
														'/your-accounts',
														'/security-settings',
														'/referrals',
														'/security-settings/2fa',
														'/account-settings',
													])}
													onClick={submenuParentClickHandler}
												>
													<div className="sidebar-nav-item__icon">
														<IconSvg name="account-settings" w="20" h="20" />
													</div>
													<div className="sidebar-nav-item__text">
														<p>Account Settings</p>
													</div>
												</div>
												<div className="sidebar-nav-item__submenu">
													<ul>
														<li>
															<NavLink
																to="/account-settings"
																className={getNavLinkClass(['/account-settings'])}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon">
																	<IconSvg name="security-settings" w="24" h="24" />
																</div>
																<div className="sidebar-nav-item__text">
																	<p>Settings</p>
																</div>
															</NavLink>
														</li>
														{!!userData && (
															<>
																{statusNames[userData?.status_id] !== 'Verified' && (
																	<li>
																		<NavLink
																			to="/verification"
																			className={getNavLinkClass(['/verification'])}
																			activeClassName=""
																		>
																			<div className="sidebar-nav-item__icon">
																				<IconSvg name="verification" w="24" h="24" />
																			</div>
																			<div className="sidebar-nav-item__text">
																				<p>Verification</p>
																			</div>
																		</NavLink>
																	</li>
																)}
															</>
														)}
														<li>
															{/*	<NavLink */}
															{/*		to="/your-accounts" */}
															{/*		className={getNavLinkClass([ */}
															{/*			'/your-accounts', */}
															{/*			'/your-accounts/main', */}
															{/*		])} */}
															{/*		activeClassName="" */}
															{/*	> */}
															{/*		<div className="sidebar-nav-item__icon"> */}
															{/*			<IconSvg name="your-accounts" w="24" h="24" /> */}
															{/*		</div> */}
															{/*		<div className="sidebar-nav-item__text"> */}
															{/*			<p>Your accounts</p> */}
															{/*		</div> */}
															{/*	</NavLink> */}
															<NavLink
																to="/security-settings"
																className={getNavLinkClass([
																	'/security-settings',
																	'/security-settings/2fa',
																])}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon">
																	<IconSvg name="security-settings" w="24" h="24" />
																</div>
																<div className="sidebar-nav-item__text">
																	<p>Security</p>
																</div>
															</NavLink>
														</li>
														{/* <li>
															<NavLink
																to="/referrals"
																className={getNavLinkClass(['/referrals'])}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon">
																	<IconSvg name="referrals" w="24" h="24" />
																</div>
																<div className="sidebar-nav-item__text">
																	<p>Referrals</p>
																</div>
															</NavLink>
														</li> */}
													</ul>
												</div>
											</li>
											<li>
												<button
													onClick={() => {
														dispatch(logoutRequest({ history }));
													}}
													type="button"
													className="sidebar-nav-item__link"
												>
													<div className="sidebar-nav-item__icon">
														<IconSvg name="logout" w="24" h="24" />
													</div>
													<div className="sidebar-nav-item__text">
														<p>Log Out</p>
													</div>
												</button>
											</li>
										</ul>
									)}
								</>
							)}
						</>
					)}
				</nav>
			</div>
		</div>
	);
};

export default WalletSideBar;
