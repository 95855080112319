import { http } from 'services/http';
import { endpoint } from '../../endpoint';

export const users: any = {
	getUserFee: ({ id, type, current_page, per_page }: any) => {
		return http
			.get(endpoint.user.USER_FEE(id, type, current_page, per_page))
			.then(({ data }) => data);
	},
};
