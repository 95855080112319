import { useSelector } from 'react-redux';
import { getUserFeesData } from '../../../redux/reducers/auth/selectors';
import { IUserFeesResponse } from '../../../redux/reducers/auth/types';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';

const TableBody = () => {
	const fees = useSelector(getUserFeesData);

	return (
		<div className="table-body">
			{fees &&
				fees.map((item: IUserFeesResponse) => (
					<div className="tr" key={item.id}>
						<div className="td">
							<div className="td-hidden-name">
								<div className="td__wrap">Currency</div>
							</div>
							<div className="td__wrap">
								<div className="td__text td__text--coin">
									{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
									<img src={`/img/currencies/${item.code}.svg`} alt="" />
									{item.code.toUpperCase()}
								</div>
							</div>
						</div>
						<div className="td">
							<div className="td-hidden-name">
								<div className="td__wrap">Fee %</div>
							</div>
							<div className="td__wrap">
								<div className="td__text">
									{toFixedNumber(item.fee.trade_fee_percent, 'fiat', true)}
								</div>
							</div>
						</div>
						<div className="td">
							<div className="td-hidden-name">
								<div className="td__wrap">Fee fixed</div>
							</div>
							<div className="td__wrap">
								<div className="td__text">
									{toFixedNumber(item.fee.trade_fee_fixed, item.code.toUpperCase(), true)}
								</div>
							</div>
						</div>
						<div className="td">
							<div className="td-hidden-name">
								<div className="td__wrap">Credit fee %</div>
							</div>
							<div className="td__wrap">
								<div className="td__text">
									{toFixedNumber(item.fee.credit_percent, 'fiat', true)}
								</div>
							</div>
						</div>
						<div className="td">
							<div className="td-hidden-name">
								<div className="td__wrap">Credit fee fixed</div>
							</div>
							<div className="td__wrap">
								<div className="td__text">
									{toFixedNumber(item.fee.credit_fee, item.code.toUpperCase(), true)}
								</div>
							</div>
						</div>
						<div className="td">
							<div className="td-hidden-name">
								<div className="td__wrap">Debit fee %</div>
							</div>
							<div className="td__wrap">
								<div className="td__text">
									{toFixedNumber(item.fee.debit_percent, 'fiat', true)}
								</div>
							</div>
						</div>
						<div className="td">
							<div className="td-hidden-name">
								<div className="td__wrap">Debit fee fixed</div>
							</div>
							<div className="td__wrap">
								<div className="td__text">
									{toFixedNumber(item.fee.debit_fee, item.code.toUpperCase(), true)}
								</div>
							</div>
						</div>
					</div>
				))}
		</div>
	);
};

export default TableBody;
