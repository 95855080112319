const TableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p>Currency</p>
				</div>
				<div className="td">
					<p>Fee %</p>
				</div>
				<div className="td">
					<p>Fee fixed</p>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
