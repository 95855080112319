import TableHeader from './TableHeader';
import TableBody from './TableBody';

const FeesTable = () => {
	return (
		<div className="table-block table-block--mt-0 mb-0">
			<div className="table-wrapper">
				<div className="table table--account-fees">
					<div className="table__title">Trading</div>
					<TableHeader />
					<TableBody />
				</div>
			</div>
		</div>
	);
};

export default FeesTable;
