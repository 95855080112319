import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { responseErrors } from '../../../services/http/responseErrors';
import { getUserFeeRequest, getUserFeeSuccess } from './reducer';
import { api } from '../../../services';

function* getUserFeeWorker({ payload }: PayloadAction<any>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const data: any = yield call(api.users.getUserFee, payload);
		yield put(getUserFeeSuccess(data));
	} catch (error) {
		// yield put(getOneUserError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* usersSaga() {
	yield takeEvery(getUserFeeRequest, getUserFeeWorker);
}
