import { useState } from 'react';
import TableHeader from './TableHeader';
import TableBodyInbound from './TableBodyInbound';
import TableBodyOutbound from './TableBodyOutbound';

export enum EBankingTableTab {
	INBOUND = 'inbound',
	OUTBOUND = 'outbound',
}

const BankingTable = () => {
	const [activeTab, setActiveTab] = useState<EBankingTableTab>(EBankingTableTab.INBOUND);

	const chooseTab = () => {
		switch (activeTab) {
			case EBankingTableTab.INBOUND:
				return <TableBodyInbound />;
			case EBankingTableTab.OUTBOUND:
				return <TableBodyOutbound />;
			default:
				return <TableBodyInbound />;
		}
	};

	return (
		<div className="table-block table-block--mt-0 mb-0">
			<div className="table-wrapper">
				<div className="table table--account-banking">
					<div className="table__title">Banking</div>
					<div className="px-10">
						<div className="table-tabs">
							<button
								type="button"
								className={activeTab === EBankingTableTab.INBOUND ? 'is-active' : ''}
								onClick={() => setActiveTab(EBankingTableTab.INBOUND)}
							>
								Inbound
							</button>
							<button
								type="button"
								className={activeTab === EBankingTableTab.OUTBOUND ? 'is-active' : ''}
								onClick={() => setActiveTab(EBankingTableTab.OUTBOUND)}
							>
								Outbound
							</button>
						</div>
					</div>
					<TableHeader />
					{chooseTab()}
				</div>
			</div>
		</div>
	);
};

export default BankingTable;
